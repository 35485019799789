@import "../../../styles/var";

.footer {
    position: relative;
    margin-top: 160px;
    padding-bottom: 55px;
    @media(max-width: $md1+px) {
        padding-bottom: 30px;
    }
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    &Body {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 569px 626px;
        gap: 120px;
        @media(max-width: $md1+px) {
            gap: 90px;
            grid-template-columns: 1fr 450px 500px;
        }
        @media(max-width: $md2+px) {
            gap: 50px;
            grid-template-columns: 1fr 450px 480px;
        }
        @media(max-width: $md3+px) {
            gap: 30px 0 70px 0;
            grid-template-columns: repeat(6, 1fr);
            .docs {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 1;
                grid-column-end: 3;
            }
            .awards {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 3;
                grid-column-end: 7;
            }
            .disclaimer {
                grid-row-start: 2;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 7;
            }
        }
        @media(max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            .docs, .awards, .disclaimer {
                grid-row-start: unset;
                grid-row-end: unset;
                grid-column-start: unset;
                grid-column-end: unset;
            }
        }
        .docs {
            .logo {
                width: 55px;
            }
            &Body {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                @media(max-width: $md1+px) {
                    gap: 12px;
                }
                a {
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(0, 0, 0, 0.7);
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
        .awards {
            &Body {
                display: flex;
                align-items: center;
                gap: 60px;
                @media(max-width: $md1+px) {
                    gap: 40px;
                }
                @media(max-width: $md6+px) {
                    gap: 25px;
                }
                .award_01, .award_02 {
                    width: 101px;
                    @media(max-width: $md1+px) {
                        width: 79px;
                    }
                    @media(max-width: $md4+px) {
                        width: 70px;
                    }
                    @media(max-width: $md6+px) {
                        width: 61px;
                    }
                }
                .award_03 {
                    width: 206px;
                    @media(max-width: $md1+px) {
                        width: 170px;
                    }
                    @media(max-width: $md4+px) {
                        width: 150px;
                    }
                    @media(max-width: $md6+px) {
                        width: 162px;
                    }
                }
            }
            &Text {
                margin-top: 20px;
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                @media(max-width: $md1+px) {
                    font-size: 14px;
                }
                @media(max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
        .disclaimer {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.7);
            &Title {
                font-weight: 400;
            }
            &Text {
                display: flex;
                flex-direction: column;
                margin: 6px 0 15px 0;
                gap: 9px;
                @media(max-width: $md1+px) {
                    gap: 8px;
                    margin: 6px 0 10px 0;
                }
                @media(max-width: $md4+px) {
                    margin: 6px 0 20px 0;
                    gap: 6px;
                }
                @media(max-width: $md6+px) {
                    gap: 10px;
                }
            }
            .rights {
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
}
