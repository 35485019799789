@import '../../../styles/var';

.overall {
    position: relative;
    margin-top: 160px;
    background: #F2F2F2;
    padding: 54px 0 127px 0;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
        padding: 40px 0 330px 0;
    }
    .image {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 1066px;
        @media(max-width: $md1+px) {
            width: 900px;
        }
        @media(max-width: $md2+px) {
            width: 800px;
        }
        @media(max-width: $md3+px) {
            display: none;
        }
        @media(max-width: $md6+px) {
            display: flex;
            top: unset;
            bottom: 0;
            width: 563px;
        }
    }
    &Body {
        position: relative;
        max-width: 888px;
        margin-left: auto;
        @media(max-width: $md1+px) {
            max-width: 700px;
        }
        @media(max-width: $md3+px) {
            max-width: 100%;
            margin: 0 auto;
            text-align: center;
        }
        .infoIcon {
            width: 32px;
        }
        .text {
            margin: 26px 0 40px 0;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.7);
            @media(max-width: $md3+px) {
                max-width: 578px;
                margin: 20px auto 40px auto;
            }
        }
        .buttons {
            display: flex;
            gap: 25px;
            @media(max-width: $md3+px) {
                justify-content: center;
            }
            @media(max-width: $md5+px) {
                // flex-direction: column;
                display: block;
            }
            .btn {
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #02A69E;
                border: 1px solid #02A69E;
                border-radius: 30px;
                padding: 14px 30px;
                cursor: pointer;
                transition: all .3s;
                display: inline-flex;
                &:nth-child(2) {
                    @media(max-width: $md5+px) {
                        margin-top: 20px;
                    }
                }
                &:hover {
                    color: #1B2A38;
                    background: #02A69E;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
}