@import '../../../styles/var';

.differenceBetween {
    position: relative;
    margin-top: 160px;
    @media(max-width: $md1+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .title {
        text-align: center;
    }
    .container {
        @media(max-width: $md3+px) {
            margin: 0!important;
            max-width: 100%!important;
            padding: 0 40px 20px 40px!important;
            overflow: scroll;
            overflow-y: hidden;
        }
        @media(max-width: $md6+px) {
            padding: 0 15px 15px 15px!important;
        }
    }
    .tableWr {
        width: 100%;
        margin-top: 60px;
        @media(max-width: $md1+px) {
            margin-top: 50px;
        }
        @media(max-width: $md3+px) {
            width: 1200px;
        }
        @media(max-width: $md4+px) {
            margin-top: 40px;
        }
        @media(max-width: $md6+px) {
            width: 1000px;
        }
        table {
            width: 100%;
            border-spacing: 0;
            tbody {
                width: 100%;
                tr {
                    font-family: $rubik;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 150%;
                    color: rgba(0, 0, 0, 0.7);
                    @media(max-width: $md1+px) {
                        font-size: 16px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                    &:nth-child(1) {
                        td {
                            padding: 37px 10px 18px 30px;
                            @media(max-width: $md6+px) {
                                padding: 11px 5px 9px 20px;
                            }
                            &:nth-child(1) {
                                font-weight: 300;
                                border-bottom: 1px solid rgba(2, 166, 158, 0.2);
                            }
                            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                                font-family: $manrope;
                                font-style: normal;
                                font-weight: 800;
                                font-size: 24px;
                                line-height: 140%;
                                color: #02A69E;
                                @media(max-width: $md6+px) {
                                    font-size: 16px;
                                }
                            }
                            &:nth-child(2), &:nth-child(4) {
                                background: #D3F9F7;
                            }
                            &:nth-child(3), &:nth-child(5) {
                                background: #EDFFFE;
                            }
                        }
                    }
                    &:nth-child(2) {
                        td {
                            padding-top: 40px;
                            @media(max-width: $md1+px) {
                                padding-top: 22px;
                            }
                            @media(max-width: $md6+px) {
                                padding-top: 11px;
                            }
                        }
                    }
                    td {
                        padding: 20px 10px 20px 30px;
                        @media(max-width: $md1+px) {
                            padding: 16px 10px 16px 30px;
                        }
                        @media(max-width: $md4+px) {
                            padding: 10px 5px 10px 30px;
                        }
                        @media(max-width: $md6+px) {
                            padding: 7px 5px 7px 20px;
                        }
                        &:nth-child(1) {
                            font-weight: 500;
                            @media(max-width: $md3+px) {
                                padding-left: 0!important
                            }
                        }
                        &:nth-child(2), &:nth-child(4) {
                            background: #EDFFFE;
                        }
                    }
                }
            }
        }
    }
}