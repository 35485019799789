@import '../../../styles/var';

.depositWidth {
    position: relative;
    margin-top: 160px;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .tableWr {
        margin: 50px 0;
        width: 931px;
        @media(max-width: $md1+px) {
            width: 760px;
        }
        @media(max-width: $md3+px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            margin: 30px 0;
        }
        table {
            width: 100%;
            position: relative;
            tr {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                position: relative;
                @media(max-width: $md1+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
                    opacity: 0.2;
                    @media(max-width: $md6+px) {
                        background: none;
                        content: unset;
                    }
                }
                td {
                    padding: 15px 0;
                    @media(max-width: $md1+px) {
                        padding: 12px 0;
                    }
                    &:nth-child(1) {
                        width: 350px;
                        @media(max-width: $md1+px) {
                            width: 300px;
                            img {
                                transform: scale(0.8);
                            }
                        }
                        @media(max-width: $md2+px) {
                            width: unset;
                        }
                        img {
                            @media(max-width: $md3+px) {
                                transform: scale(1);
                                height: 20px;
                            }
                            @media(max-width: $md6+px) {
                                height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .phone {
        position: absolute;
        z-index: 5;
        top: 75px;
        right: 50px;
        width: 378px;
        @media(max-width: $md1+px) {
            width: 340px;
        }
        @media(max-width: $md2+px) {
            width: 300px;
            right: 0;
        }
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .coin_01 {
        position: absolute;
        z-index: 6;
        top: 140px;
        right: 440px;
        width: 141px;
        transform: translateX(80px) translateY(-80px) scale(0);
        @media(max-width: $md1+px) {
            width: 110px;
            right: 400px;
        }
        @media(max-width: $md2+px) {
            right: 310px;
            width: 100px;
        }
    }
    .coin_02 {
        position: absolute;
        z-index: 6;
        top: 370px;
        right: 380px;
        width: 123px;
        transform: translateX(10px) translateY(-80px) scale(0);
        @media(max-width: $md1+px) {
            width: 90px;
            right: 350px;
            top: 350px;
        }
        @media(max-width: $md2+px) {
            right: 270px;
            top: 300px;
        }
    }
    .coin_03 {
        position: absolute;
        z-index: 6;
        top: 300px;
        right: 120px;
        width: 94px;
        transform: translateX(-40px) translateY(-80px) scale(0);
        @media(max-width: $md1+px) {
            width: 80px;
            top: 270px;
        }
        @media(max-width: $md2+px) {
            top: 240px;
            right: 0px;
        }
    }
    .coin_04 {
        position: absolute;
        z-index: 4;
        top: 170px;
        right: 0px;
        width: 156px;
        transform: translateX(-50px) translateY(-20px) scale(0);
        @media(max-width: $md1+px) {
            width: 130px;
        }
        @media(max-width: $md2+px) {
            width: 110px;
            right: -40px;
        }
    }
    .coin_01, .coin_02, .coin_03, .coin_04 {
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .phone_mob {
        display: none;
        @media(max-width: $md3+px) {
            display: flex;
            margin: 60px auto 0 auto;
            width: 491px;
        }
        @media(max-width: 600px) {
            width: 90%;
            margin-top: 50px;
        }
    }
}