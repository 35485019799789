@import '../../../styles/var';

.featuresOf {
    position: relative;
    margin-top: 160px;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .title {
        text-align: center;
        color: #1B2A38;
    }
    .cards {
        margin-top: 60px;
        display: flex;
        justify-content: space-around;
        @media(max-width: $md3+px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
        @media(max-width: $md6+px) {
            grid-template-columns: repeat(1, 1fr);
            margin-top: 40px;
        }
        .card {
            text-align: center;
            &:hover {
                .cardIcon {
                    transform: scale(1.05);
                }
            }
            &Icon {
                width: 60px;
                transition: all .3s;
            }
            &Text {
                margin-top: 20px;
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
                color: rgba(0, 0, 0, 0.7);
                max-width: 270px;
                margin-left: auto;
                margin-right: auto;
                @media(max-width: $md1+px) {
                    font-size: 20px;
                }
                @media(max-width: $md4+px) {
                    font-size: 18px;
                }
                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }
        }
    }
}