@import "../../../styles/var";

.button {
    padding: 14px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    background: #02A69E;
    border-radius: 30px;
    font-family: $rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #1B2A38;
    transition: all .3s;
    @media(max-width: $md6+px) {
        font-size: 14px;
        line-height: 17px;
    }
    &:hover {
        color: #02A69E;
        background: #C5EDEB;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}