@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 80px;
    height: calc(100vh - 80px);
    min-height: 800px;
    background-image: url('../../../assets/img/MainPage/Banner/banner_bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @media(max-width: $md1+px) {
        margin-top: 60px;
        height: calc(100vh - 60px);
        min-height: 600px;
    }
    @media(max-width: $md3+px) {
        height: 600px;
    }
    @media(max-width: $md6+px) {
        background-image: url('../../../assets/img/MainPage/Banner/banner_bg_mob.png');
        background-position: bottom center;
    }
    &Body {
        position: relative;
        .header {
            padding-top: 100px;
            max-width: 953px;
            color: #fff;
            @media(max-width: $md1+px) {
                padding-top: 60px;
            }
            @media(max-width: $md4+px) {
                max-width: 493px;
            }
            @media(max-width: $md6+px) {
                padding-top: 40px;
            }
            .title {

            }
            .subtitle {
                @media(max-width: $md4+px) {
                    font-size: 24px;
                }
                @media(max-width: $md6+px) {
                    font-size: 32px;
                }
            }
        }
        .text {
            margin: 20px 0 40px 0;
            color: rgba(255, 255, 255, 0.7);
            font-family: $rubik;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            @media(max-width: $md1+px) {
                font-size: 18px;
            }
            @media(max-width: $md4+px) {
                font-size: 16px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                margin: 20px 0 30px 0;
            }
        }
    }
}