@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("../assets/fonts/Rubik-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}