@import "../../styles/var";
.about {
    overflow: hidden;
}
.wrapBg {
    @media (max-width: $md3+px) {
        background: url("../../assets/img/AboutPage/1/111.png") 0 0 / cover no-repeat;
    }
    @media (max-width: $md5+px) {
        background: url("../../assets/img/AboutPage/1/111.png") 0px 0 / cover no-repeat;
    }
    @media (max-width: 700px) {
        background: url("../../assets/img/AboutPage/1/111.png") -50px 0 / cover no-repeat;
    }
    @media (max-width: 650px) {
        background: url("../../assets/img/AboutPage/1/111.png") -100px 0 / cover no-repeat;
    }
    @media (max-width: 600px) {
        background: url("../../assets/img/AboutPage/1/111.png") -150px 0 / cover no-repeat;
    }
    @media (max-width: 550px) {
        background: url("../../assets/img/AboutPage/1/111.png") -200px 0 / cover no-repeat;
    }
    @media (max-width: 500px) {
        background: url("../../assets/img/AboutPage/1/111.png") -280px 0 / cover no-repeat;
    }
    @media (max-width: $md6+px) {
        background: url("../../assets/img/AboutPage/1/1111.png") 0 0 / cover no-repeat;
    }
}
