@import '../../../styles/var';

.faq {
    position: relative;
    margin-top: 160px;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .title {
        font-family: $manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 140%;
        color: #1B2A38;
        text-align: center;
        @media(max-width: $md1+px) {
            font-size: 36px;
            line-height: 66px;
        }
        @media(max-width: $md4+px) {
            font-size: 32px;
            line-height: 49px;
        }
        @media(max-width: $md6+px) {
            font-size: 28px;
            line-height: 140%;
            text-align: left;
        }
    }
    .tabs {
        margin-top: 20px;
        .tab {
            padding: 40px 150px;
            border-bottom: 1px solid rgba(2, 166, 158, 0.2);
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            @media(max-width: $md1+px) {
                padding: 30px 100px;
            }
            @media(max-width: $md4+px) {
                padding: 30px 50px;
            }
            @media(max-width: $md5+px) {
                padding: 30px 0;
            }
            &Title {
                position: relative;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 133%;
                color: #000000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                @media(max-width: $md1+px) {
                    font-size: 22px;
                }
                @media(max-width: $md4+px) {
                    font-size: 20px;
                }
                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
                &Status {
                    width: 24px;
                    height: 24px;
                    position: relative;
                    @media(max-width: $md4+px) {
                        width: 22px;
                        height: 22px;
                    }
                    @media(max-width: $md6+px) {
                        width: 18px;
                        height: 18px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        left: 0;
                        top: calc(50% - 1px);
                        background: #02A69E;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        height: 100%;
                        width: 2px;
                        left: calc(50% - 1px);
                        top: 0;
                        background: #02A69E;
                        transition: all .3s;
                    }
                    &.activeStatus {
                        &::after {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            &Content {
                font-family: $rubik;
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                margin-top: 30px;
                max-width: 80%;
                @media(max-width: $md1+px) {
                    font-size: 18px;
                }
                @media(max-width: $md4+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    max-width: 90%;
                }
            }
        }
    }
}