@import "../../../styles/var";
.four {
    margin: 160px 0;
    @media (max-width: $md4+px) {
        margin: 140px 0;
    }
    @media (max-width: $md6+px) {
        margin: 120px 0;
    }
}
.fourBody {
    position: relative;
    padding: 87px 0 147px 0;
    background-color: #f2f2f2;
    @media (max-width: $md4+px) {
        padding: 87px 0 650px 0;
    }
    @media (max-width: 600px) {
        padding: 57px 0 550px 0;
    }
    @media (max-width: 530px) {
        padding: 57px 0 450px 0;
    }
    @media (max-width: 430px) {
        padding: 47px 0 350px 0;
    }
}
.fourGirl {
    width: 804px;
    height: 733px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url("../../../assets/img/StartPage/4/1.png") right 0 / contain no-repeat;
    @media (max-width: $md1+px) {
        width: 704px;
        height: 633px;
    }
    @media (max-width: $md2+px) {
        width: 604px;
        height: 533px;
    }
    @media (max-width: $md3+px) {
        right: -100px;
    }
    @media (max-width: $md4+px) {
        right: 0;
    }
    @media (max-width: 600px) {
        width: 504px;
        height: 433px;
    }
    @media (max-width: 530px) {
        width: 404px;
        height: 333px;
    }
    @media (max-width: 430px) {
        width: 304px;
        height: 233px;
    }
}
.fourMoney {
    width: 1115px;
    height: 998px;
    position: absolute;
    right: 0;
    top: 80px;
    background: url("../../../assets/img/StartPage/4/2.png") right 0 / contain no-repeat;
    @media (max-width: $md1+px) {
        width: 1015px;
        height: 898px;
    }
    @media (max-width: $md2+px) {
        width: 915px;
        height: 798px;
    }
    @media (max-width: $md3+px) {
        background: url("../../../assets/img/StartPage/4/22.png") right 0 / contain no-repeat;
    }
    @media (max-width: $md4+px) {
        top: unset;
        bottom: -150px;
        right: 100px;
    }
    @media (max-width: 600px) {
        width: 815px;
        height: 698px;
    }
    @media (max-width: 530px) {
        width: 715px;
        height: 598px;
        right: 70px;
    }
    @media (max-width: 430px) {
        width: 615px;
        height: 498px;
        right: 30px;
    }
}
.fourTitle {
    max-width: 820px;
    color: #1b2a38;
    font-weight: 500;
    margin-bottom: 60px;
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.fourWelcomeTitle {
    margin-bottom: 20px;
    color: #02a69e;
}
.fourWelcomeSubtitle {
    margin-bottom: 60px;
    font-weight: 700;
    color: #02a69e;
}
.fourWelcomeText {
    margin-bottom: 30px;
    font-weight: 300;
}
.fourWelcomeList {
}
.fourWelcomeListItem {
    margin-bottom: 8px;
    font-weight: 300;
    padding-left: 33px;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 7px;
        position: absolute;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/StartPage/3/arrow.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            top: 4px;
        }
        @media (max-width: $md4+px) {
            top: 2px;
        }
    }
}
.fourBtn {
    margin-top: 30px;
}
