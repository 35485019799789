@import "../../../styles/var";
.first {
    margin-top: 80px;
    @media (max-width: $md1+px) {
        margin-top: 60px;
    }
}

.firstBody {
    position: relative;
    padding: 100px 0 211px 0;
    @media (max-width: $md1+px) {
        padding: 60px 0 140px 0;
    }
    @media (max-width: $md3+px) {
        padding: 60px 0 210px 0;
    }
    @media (max-width: $md4+px) {
        padding: 40px 0 280px 0;
    }
    @media (max-width: $md5+px) {
        padding: 40px 0 230px 0;
    }
    @media (max-width: $md6+px) {
        padding: 40px 0 420px 0;
    }
}
.firstImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 1920px;
    height: 1000px;
    background: url("../../../assets/img/StartPage/1/1.png") 0 0 / cover no-repeat;
    @media (min-width: 1921px) {
        width: 100%;
        background: url("../../../assets/img/StartPage/1/1.png") 0 0 / cover no-repeat;
    }
    @media (max-width: $md1+px) {
        width: 1650px;
        height: 787.5px;
    }
    @media (max-width: $md3+px) {
        left: -400px;
    }
    @media (max-width: $md5+px) {
        background: url("../../../assets/img/StartPage/1/111.png") 0 0 / cover no-repeat;
        height: 799px;
        width: 768px;
        left: 0;
    }

    @media (max-width: 600px) {
        left: -180px;
    }
    @media (max-width: $md6+px) {
        left: 0;
        background: url("../../../assets/img/StartPage/1/1111.png") 0 0 / cover no-repeat;
        height: 800px;
        width: 480px;
    }
}
.firstTitle {
    color: #fff !important;
}
.firstSubtitle {
    color: #fff !important;
    margin-bottom: 90px;
    @media (max-width: $md1+px) {
        margin-bottom: 60px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 30px;
    }
}
.titleList {
    color: #fff !important;
    margin-bottom: 16px;
}
.firstList {
}
.firstListItem {
    padding-left: 38px;
    margin-bottom: 18px;
    position: relative;
    color: #4dd1cb;
    &:last-child {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 2px;
        top: 8px;
        position: absolute;
        width: 18px;
        height: 18px;
        @media (max-width: $md1+px) {
            top: 4px;
        }
        @media (max-width: $md4+px) {
            top: 2px;
        }
        background: url("../../../assets/img/StartPage/1/arrow.png") 0 0 / contain no-repeat;
    }
}
.cont {
    position: relative;
    z-index: 3;
}
