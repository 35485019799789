@import "../../../styles/var";
.second {
    position: relative;
    z-index: 3;
}
.secondBody {
}
.secondTitle {
    margin-bottom: 40px;
    color: #ffffff;
    text-align: center;
    @media (max-width: $md6+px) {
        color: #1b2a38 !important;
    }
}
.secondRow {
    display: flex;
    justify-content: space-between;
    max-width: 1356px;
    margin: 0 auto;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        max-width: 560px;
    }
    @media (max-width: $md4+px) {
        max-width: 560px;
    }
    @media (max-width: 640px) {
        justify-content: center;
    }
}
.secondRowColumn {
    padding: 20px;
    flex: 0 1 309px;
    text-align: center;
    background: #243443;
    border-radius: 10px;
    @media (max-width: $md2+px) {
        flex: 0 1 290px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 270px;
        &:first-child {
            margin-bottom: 20px;
        }
        &:nth-child(2) {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 640px) {
        margin: 0 50px 20px 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media (max-width: $md6+px) {
        margin: 0 0px 20px 0px;
    }
}
.secondRowColumnImg {
    img {
        height: 40px;
    }
    margin-bottom: 20px;
}
.secondRowColumnText {
    color: rgba(255, 255, 255, 0.7) !important;
    text-align: center;
}
