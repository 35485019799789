@import '../../../styles/var';

.chooseThe {
    position: relative;
    margin-top: 160px;
    margin-bottom: 200px;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .title {
        font-family: $manrope;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 140%;
        text-align: center;
        color: #1B2A38;
        max-width: 800px;
        margin: 0 auto;
        @media(max-width: $md1+px) {
            font-size: 40px;
        }
        @media(max-width: $md4+px) {
            font-size: 32px;
        }
        @media(max-width: $md6+px) {
            font-size: 28px;
        }
    }
    .subtitle {
        text-align: center;
        color: #000000;
        margin: 20px 0 40px 0;
        font-weight: 400;
        @media(max-width: $md6+px) {
            font-size: 18px;
        }
    }
    .cards {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
        @media(max-width: $md6+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }
        .card {
            position: relative;
            background: #F2F2F2;
            box-shadow: 0px 0px 24px rgba(222, 222, 222, 0.25);
            border-radius: 8px;
            padding: 20px 30px 37px 30px;
            overflow: hidden;
            @media(min-width: $md3+px) {
                &:hover {
                    .cardBtn {
                        transform: translateX(0);
                    }
                    .cardIcon {
                        height: 60px;
                        @media(max-width: $md1+px) {
                            height: 40px;
                        }
                    }
                    .cardText {
                        margin-bottom: 20px;
                    }
                }
            }
            &Icon {
                height: 80px;
                transition: all .3s;
                @media(max-width: $md1+px) {
                    height: 60px;
                }
            }
            &Text {
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                margin-top: 20px;
                transition: all .3s;
                @media(max-width: $md1+px) {
                    font-size: 16px;
                }
                @media(max-width: $md3+px) {
                    margin-bottom: 15px;
                    margin-top: 10px;
                }
            }
            &Btn {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: #02A69E;
                position: relative;
                display: inline-flex;
                cursor: pointer;
                position: absolute;
                bottom: 18px;
                left: 30px;
                transform: translateY(40px);
                transition: all .3s;
                @media(max-width: $md1+px) {
                    font-size: 16px;
                }
                @media(max-width: $md3+px) {
                    bottom: 20px;
                    transform: translateY(0);
                }   
                &:hover {
                    color: #09726d;
                }
                &::before {
                    content: '';
                    position: absolute;
                    right: -10px;
                    top: 6px;
                    width: 5px;
                    height: 9px;
                    background-image: url('../../../assets/img/MainPage/ChooseThe/arrow.svg');
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
                    transform: scale(1.3);
                }
            }
        }
    }
}