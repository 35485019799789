@import "../../../styles/var";
.three {
    margin: 160px 0;
    @media (max-width: $md4+px) {
        margin: 140px 0;
    }
    @media (max-width: $md6+px) {
        margin: 120px 0;
    }
}
.threeBody {
}
.threeSpoiler {
    padding-left: 74px;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: $md3+px) {
        padding-left: 40px;
    }
    @media (max-width: $md5+px) {
        padding-left: 20px;
        margin-bottom: 20px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 4px;
        height: 100%;
        transition: all 0.3s linear;
        background-color: #d9d9d9;
        @media (max-width: $md6+px) {
            width: 2px;
        }
    }
    &.active {
        &:after {
            background-color: #02a69e;
        }
    }
}
.threeSpoilerTitle {
    transition: all 0.3s linear;
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    &.active {
        color: #000;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.threeSpoilerRow {
    display: flex;
    justify-content: space-between;

    padding-bottom: 40px;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 0;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 20px;
    }
}
.threeSpoiler {
    &:first-child {
        .threeSpoilerRowRight {
            top: -150px;
            position: relative;
            @media (max-width: $md2+px) {
                top: -20px;
            }
            @media (max-width: $md3+px) {
                top: 0;
            }
        }
        @media (max-width: $md3+px) {
            .threeSpoilerRowLeftBtn {
                margin-bottom: 40px;
            }
        }
    }
}
.threeSpoilerRowLeft {
    flex: 0 1 800px;

    @media (max-width: $md1+px) {
        flex: 0 1 700px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.threeSpoilerRowLeftText {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 15px;
    }
}
.threeSpoilerRowLeftBtn {
    margin-top: 40px;

    @media (max-width: $md3+px) {
        margin-top: 30px;
    }
}
.threeSpoilerRowRight {
    flex: 0 1 429px;
}
.threeSpoilerRowRightWrapper {
}
.threeSpoilerRowRightImg {
    @media (max-width: $md3+px) {
        margin-top: 30px;
    }
    img {
        width: 100%;
    }
}
.threeSpoilerRowRightTitle {
    font-weight: 500;
    color: #000000;
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.threeSpoilerRowRightList {
    @media (max-width: $md3+px) {
        margin-bottom: 30px;
    }
}
.threeSpoilerRowRightItem {
    font-weight: 300;

    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 8px;
    padding-left: 33px;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 8px;
        position: absolute;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/StartPage/3/arrow.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            top: 4px;
        }
        @media (max-width: $md4+px) {
            top: 2px;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}
