@import '../../../styles/var';

.tradingPlatform {
    position: relative;
    margin-top: 160px;
    background-image: url('../../../assets/img/MainPage/TradingPlatform/bg.png');
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    @media(max-width: $md1+px) {
        margin-top: 140px;
    }
    @media(max-width: $md4+px) {
        background-position: top left;
    }
    @media(max-width: $md5+px) {
        background-position: top center;
    }
    @media(max-width: $md6+px) {
        background-image: url('../../../assets/img/MainPage/TradingPlatform/bg_mob.png');
        background-position: bottom center;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    &Body {
        position: relative;
        padding: 100px 0 120px 0;
        max-width: 800px;
        @media(max-width: $md1+px) {
            padding: 90px 0;
        }
        @media(max-width: $md5+px) {
            padding: 40px 0 200px 0;
        }
        .logo {
            display: none;
            @media(max-width: $md4+px) {
                display: flex;
                margin-bottom: 40px;
            }
            @media(max-width: $md6+px) {
                height: 40px;
            }
        }
        .title {
            color: #FFFFFF;
            font-weight: 500;
        }
        .subtitle {
            color: #02A69E;
            margin: 40px 0 20px 0;
            font-family: $manrope;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 44px;
            @media(max-width: $md1+px) {
                font-size: 24px;
                line-height: 33px;
                max-width: 512px;
            }
            @media(max-width: $md4+px) {
                font-size: 20px;
                line-height: 27px;
                margin: 20px 0 40px 0;
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: #FFFFFF;
            @media(max-width: $md6+px) {
                gap: 15px;
            }
        }
    }
}