@import "../../../styles/var";
.first {
    margin-top: 80px;
    @media (max-width: $md1+px) {
        margin-top: 60px;
    }
}

.firstBody {
    padding: 128px 0 134px 0;
    background: url("../../../assets/img/EducationPage/1/1.png") 0 0 / cover no-repeat;
    @media (max-width: $md1+px) {
        padding: 50px 0 134px 0;
        background: url("../../../assets/img/EducationPage/1/11.png") 0 0 / cover no-repeat;
    }
    @media (max-width: $md4+px) {
        background: url("../../../assets/img/EducationPage/1/111.png") 0 0 / cover no-repeat;
        padding: 50px 0 94px 0;
    }
    @media (max-width: $md6+px) {
        background: url("../../../assets/img/EducationPage/1/1111.png") 0 0 / cover no-repeat;
        padding: 50px 0 50px 0;
    }
}
.firstTitle {
    max-width: 1123px;
    font-weight: 500;

    color: #ffffff;
    margin-bottom: 40px;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
}

.firstText {
    font-weight: 300 !important;
    color: rgba(255, 255, 255, 0.7) !important;
    max-width: 662px;
    @media (max-width: $md4+px) {
        max-width: 572px;
    }
}
.mb40 {
    margin-bottom: 40px;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 12px;
    }
}
.firstList {
    margin: 30px 0 25px 0;
    @media (max-width: $md5+px) {
        margin: 20px 0 20px 0;
    }
}
.firstListItem {
    color: #4dd1cb;
    padding-left: 38px;
    margin-bottom: 18px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 2px;
        top: 10px;
        position: absolute;
        width: 18px;
        height: 18px;
        @media (max-width: $md1+px) {
            top: 6px;
        }
        @media (max-width: $md4+px) {
            top: 3px;
        }
        background: url("../../../assets/img/StartPage/1/arrow.png") 0 0 / contain no-repeat;
    }
}
