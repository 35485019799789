@import "../../../styles/var";
.first {
    position: relative;
    top: -2px;
    margin: 0 0 160px 0;
    @media (max-width: $md4+px) {
        margin: 0 0 140px 0;
    }
    @media (max-width: $md6+px) {
        margin: 0 0 120px 0;
    }
}

.firstBody {
    padding: 188px 0 232px 0;
    background: url("../../../assets/img/EducationPage/2/1.png") 0 0 / cover no-repeat;
    @media (max-width: $md1+px) {
        padding: 100px 0 100px 0;
        background: url("../../../assets/img/EducationPage/2/11.png") 0 0 / cover no-repeat;
    }
    @media (max-width: $md4+px) {
        padding: 76px 0 125px 0;
        background: url("../../../assets/img/EducationPage/2/111.png") 0 0 / cover no-repeat;
    }
    @media (max-width: $md6+px) {
        padding: 81px 0 104px 0;
        background: url("../../../assets/img/EducationPage/2/1111.png") 0 0 / cover no-repeat;
    }
}
.firstTitle {
    max-width: 1123px;
    font-weight: 500;

    color: #ffffff;
    margin-bottom: 25px;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
}

.firstText {
    font-weight: 300 !important;
    color: rgba(255, 255, 255, 0.7) !important;
    max-width: 662px;
    @media (max-width: $md4+px) {
        max-width: 572px;
    }
}
.mb40 {
    margin-bottom: 20px;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 12px;
    }
}
.firstList {
    margin: 30px 0 25px 0;
    @media (max-width: $md5+px) {
        margin: 20px 0 20px 0;
    }
}
.firstListItem {
    color: #4dd1cb;
    padding-left: 38px;
    margin-bottom: 18px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 2px;
        top: 10px;
        position: absolute;
        width: 18px;
        height: 18px;
        @media (max-width: $md1+px) {
            top: 4px;
        }
        @media (max-width: $md4+px) {
            top: 2px;
        }
        background: url("../../../assets/img/StartPage/1/arrow.png") 0 0 / contain no-repeat;
    }
}
.firstOver {
    display: flex;
    justify-content: flex-end;
    @media (max-width: $md4+px) {
        justify-content: flex-start;
    }
}
.firstWrap {
    max-width: 662px;
    @media (max-width: $md1+px) {
        max-width: 572px;
    }
}
