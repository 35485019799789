@import '../../../styles/var';

.feelConfidentSwiper {
    position: relative;
    padding: 0 calc(100vw/2 - 1620px/2);
    max-width: 1920px;
    min-height: 100%;
    @media(min-width: 1920px) {
        padding: 0 150px;
    }
    @media(max-width: $md1+px) {
        padding: 0 calc(100vw/2 - 1300px/2);
    }
    @media(max-width: $md2+px) {
        padding: 0 calc(100vw/2 - 1200px/2);
    }
    @media(max-width: $md3+px) {
        padding: 0 40px;
    }
    @media(max-width: $md6+px) {
        padding: 0 15px;
    }
    .feelConfidentSwiperSlide {
        width: 623px;
        padding: 20px 0;
        // height: 100%!important;
        height: auto !important;
        @media(max-width: $md1+px) {
            width: 482px;
        }
        @media(max-width: $md4+px) {
            width: 394px;
        }
        @media(max-width: $md6+px) {
            width: 100%;
            padding-bottom: 30px;
        }
    }
    .swiper-pagination {
        @media(min-width: 481px) {
            display: none;
        }
        bottom: 0px;
        .swiper-pagination-bullet-active {
            background: #02A69E;
        }
    }
}