@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 80px;
    background: #1B2A38;
    @media(max-width: $md1+px) {
        margin-top: 60px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/TradingAccountsPage/Banner/bg.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        @media(max-width: $md4+px) {
            height: 60%;
        }
        @media(max-width: $md6+px) {
            height: 100%;
            background-image: url('../../../assets/img/TradingAccountsPage/Banner/bg_mob.png');
        }
    }
    &Body {
        position: relative;
        padding-top: 100px;
        padding-bottom: 105px;
        @media(max-width: $md1+px) {
            padding-top: 80px;
            padding-bottom: 90px;
        }
        @media(max-width: $md4+px) {
            padding-top: 60px;
            padding-bottom: 65px;
        }
        @media(max-width: $md6+px) {
            padding-top: 40px;
            padding-bottom: 50px;
        }
        .title {
            color: #FFFFFF;
            font-weight: 500;
            max-width: 974px;
            @media(max-width: $md1+px) {
                max-width: 740px;
            }
            @media(max-width: $md4+px) {
                max-width: 541px;
            }
            @media(max-width: $md6+px) {
                font-size: 28px;
                line-height: 38px;
            }
        }
        .subtitle {
            font-family: $manrope;
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 140%;
            color: #02A69E;
            margin: 80px 0 50px 0;
            @media(max-width: $md1+px) {
                font-size: 36px;
                margin: 60px 0 40px 0;
            }
            @media(max-width: $md4+px) {
                font-size: 32px;
                margin: 80px 0 60px 0;
            }
            @media(max-width: $md6+px) {
                font-size: 24px;
                margin: 110px 0 40px 0;
            }
        }
        .subtitle_01 {
            
        }
        .subtitle_02 {
            margin-top: 80px;
            @media(max-width: $md6+px) {
                margin-top: 60px;
            }
        }
        .subtitle_03 {
            margin-top: 80px;
            @media(max-width: $md1+px) {
                margin-top: 70px;
            }
            @media(max-width: $md6+px) {
                margin-top: 60px;
            }
        }
        .subtitle_01, .subtitle_02, .subtitle_03 {
            margin-bottom: 20px;
            color: #FFFFFF;
            font-weight: 300;
            @media(max-width: $md6+px) {
                margin-bottom: 15px;
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: rgba(255, 255, 255, 0.7);
            max-width: 662px;
            @media(max-width: $md1+px) {
                max-width: 581px;
            }
            @media(max-width: $md4+px) {
                gap: 15px;
            }
        }
        ul {
            color: #4DD1CB;
            display: flex;
            flex-direction: column;
            gap: 15px;
            position: relative;
            @media(max-width: $md6+px) {
                gap: 12px;
            }
            li {
                padding-left: 38px;
                position: relative;
                @media(max-width: $md6+px) {
                    padding-left: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    left: 0;
                    top: calc(50% - 9px);
                    background-image: url('../../../assets/img/TradingAccountsPage/Banner/list_marker.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    @media(max-width: $md6+px) {
                        width: 14px;
                        height: 14px;
                        top: calc(50% - 7px);
                    }
                }
            }
        }
        .text, ul {
            font-family: $rubik;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 150%;
            @media(max-width: $md1+px) {
                font-size: 16px;
                max-width: 581px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
        }
    }
}