@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 80px;
    background: #1B2A38;
    &::before {
        content: '';
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url('../../../assets/img/SecurityPage/Banner/bg_top.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        @media(max-width: $md3+px) {
            background-image: url('../../../assets/img/SecurityPage/Banner/bg_top_mob.png');
            top: -100px;
        }
        @media(max-width: $md4+px) {
            top: 0;
        }
        @media(max-width: $md5+px) {
            top: 150px;
        }
        @media(max-width: $md6+px) {
            top: 190px;
            width: 480px;
            right: 0;
            left: unset;
        }
    }
    &::after {
        content: '';
        position: absolute;
        z-index: 4;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background-image: url('../../../assets/img/SecurityPage/Banner/bg_bottom.png');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
        @media(max-width: $md3+px) {
            width: 130%;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        @media(max-width: $md6+px) {
            display: none;
        }
    }
    @media(max-width: $md1+px) {
        margin-top: 60px;
    }
    &Body {
        position: relative;
        z-index: 5;
        padding-top: 128px;
        padding-bottom: 120px;
        @media(max-width: $md1+px) {
            padding-top: 70px;
            padding-bottom: 75px;
        }
        @media(max-width: $md4+px) {
            padding-top: 40px;
            padding-bottom: 30px;
        }
        .title {
            color: #FFFFFF;
            font-weight: 500;
        }
        .subtitle {
            color: #FFFFFF;
            margin: 60px 0 20px 0;
            @media(max-width: $md6+px) {
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 15px;
            }
        }
        .text, .securityText {
            font-family: $rubik;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 150%;
            color: rgba(255, 255, 255, 0.7);
            max-width: 662px;
            @media(max-width: $md1+px) {
                font-size: 16px;
                max-width: 540px;
            }
            @media(max-width: $md4+px) {
                max-width: 452px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
        }
        .btn {
            margin: 80px 0 270px 0;
            @media(max-width: $md1+px) {
                margin-top: 60px;
            }
            @media(max-width: $md3+px) {
                margin-bottom: 240px;
            }
            @media(max-width: $md6+px) {
                margin: 40px 0 210px 0;
            }
        }
        .securityTitle {
            color: #FFFFFF;
        }
        .securityText {
            color: rgba(255, 255, 255, 0.7);
            margin: 20px 0 80px 0;
            @media(max-width: $md1+px) {
                margin-bottom: 60px;
            }
            @media(max-width: $md4+px) {
                margin: 26px 0;
            }
            @media(max-width: $md6+px) {
                margin: 20px 0;
            }
        }
        .cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 20px;
            @media(max-width: $md4+px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
            }
            @media(max-width: 700px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(5, 1fr);
            }
            .card {
                background: #243443;
                box-shadow: 0px 0px 24px #1B2A38;
                border-radius: 10px;
                padding: 20px 30px 24px 30px;
                &:nth-child(4) {
                    transform: translateX(calc(50% + 10px));
                    @media(max-width: $md4+px) {
                        transform: none;
                    }
                }
                &:nth-child(5) {
                    transform: translateX(calc(50% + 10px));
                    @media(max-width: $md4+px) {
                        transform: none;
                    }
                }
                @media(max-width: $md1+px) {
                    padding-bottom: 20px;
                }
                @media(max-width: $md4+px) {
                    padding: 20px;
                }
                &Icon {
                    height: 60px;
                    @media(max-width: $md1+px) {
                        height: 40px;
                    }
                }
                &Title {
                    margin: 60px 0 15px 0;
                    font-family: $manrope;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 33px;
                    color: #4DD1CB;
                    @media(max-width: $md1+px) {
                        font-size: 20px;
                        line-height: 27px;
                        margin: 40px 0 20px 0;
                    }
                    @media(max-width: $md4+px) {
                        margin: 30px 0 10px 0;
                    }
                }
                &Text {
                    font-family: $rubik;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 150%;
                    color: rgba(255, 255, 255, 0.7);
                    @media(max-width: $md1+px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}