@import '../../../styles/var';

.feelConfident {
    position: relative;
    margin-top: 160px;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .title {
        max-width: 867px;
        margin: 0 auto;
        text-align: center;
        color: #1B2A38;
    }
    .cards {
        cursor: grab;
        margin-top: 40px;
        // padding: 20px 0;
        @media(max-width: $md4+px) {
            margin-top: 20px;
        }
        .card {
            background: #FFFFFF;
            box-shadow: 0px 0px 24px rgba(207, 207, 207, 0.25);
            border-radius: 10px;
            // background-color: grey;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 60px;
            padding: 40px 40px 40px 60px;
            transition: all .1s;
            &:hover {
                background: #F2F2F2;
                .cardImage {
                    filter: grayscale(100%);
                }
            }
            @media(max-width: $md1+px) {
                gap: 50px;
                padding: 40px 20px 30px 40px;
            }
            @media(max-width: $md4+px) {
                gap: 40px;
                padding: 30px 16px 20px 30px;
            }
            @media(max-width: $md6+px) {
                gap: 30px;
            }
            &Image {
                transition: all .1s;
                position: absolute;
                z-index: 1;
                width: 482px;
                top: 66px;
                left: 0;
                right: 0;
                margin: 0 auto;
                @media(max-width: $md1+px) {
                    width: 380px;
                    top: 100px;
                }
                @media(max-width: $md4+px) {
                    width: 300px;
                    top: 80px;
                }
                @media(max-width: $md6+px) {
                    width: 286px;
                    top: 83px;
                }
            }
            &Content {
                position: relative;
                z-index: 5;
            }
            &Header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &Title {
                font-family: $manrope;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 140%;
                color: #02A69E;
                @media(max-width: $md1+px) {
                    font-size: 36px;
                }
                @media(max-width: $md4+px) {
                    font-size: 29px;
                }
                @media(max-width: $md6+px) {
                    font-size: 24px;
                }
            }
            &Buttons {
                display: flex;
                gap: 20px;
                &Btn {
                    font-family: $manrope;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 140%;
                    color: #02A69E;
                    border: 1px solid #02A69E;
                    border-radius: 30px;
                    padding: 8px 16px;
                    @media(max-width: $md1+px) {
                        font-size: 14px;
                    }
                    @media(max-width: $md4+px) {
                        font-size: 11px;
                    }
                }
            }
            &Text {
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.7);
                margin: 20px 0 50px 0;
                height: 60px;
                @media(max-width: $md1+px) {
                    font-size: 16px;
                    height: 48px;
                }
                @media(max-width: $md4+px) {
                    font-size: 13px;
                    height: 40px;
                    margin: 16px 0 20px 0;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    height: 46px;
                    margin: 10px 0 30px 0;
                }
            }
            &Table {
                display: flex;
                flex-direction: column;
                gap: 20px;
                &Row {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    font-family: $rubik;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    @media(max-width: $md4+px) {
                        font-size: 13px;
                    }
                    &Left {
                        color: rgba(0, 0, 0, 0.5);
                    }
                    &Right {
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }
            &Btn {
                position: relative;
                z-index: 5;
                justify-content: center;
                display: flex;
            }
        }
    }
    .pagination {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        @media(max-width: $md6+px) {
            display: none;
        }
        &Left {
            transform: rotate(180deg);
        }
        &Item {
            cursor: pointer;
            width: 40px;
            height: 40px;
        }
    }
}