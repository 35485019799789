@import '../../../styles/var';

.industryLeading {
    position: relative;
    margin-top: 160px;
    background: #F2F2F2;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .left_img {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 420px;
        @media(max-width: $md1+px) {
            width: 350px;
        }
        @media(max-width: $md2+px) {
            width: 320px;
        }
        @media(max-width: $md3+px) {
            width: 250px;
        }
        @media(max-width: $md6+px) {
            width: 220px;
        }
    }
    .pig {
        position: absolute;
        z-index: 2;
        right: calc(50% - 1150px);
        bottom: -115px;
        width: 845px;
        transform: scale(0.7);
        @media(max-width: $md1+px) {
            width: 600px;
            right: calc(50% - 900px);
            bottom: -80px;
        }
        @media(max-width: $md3+px) {
            bottom: -40px;
            right: calc(50% - 700px);
            transform: scale(1);
        }
        @media(max-width: $md4+px) {
            width: 500px;
            bottom: -60px;
            right: calc(50% - 500px);
        }
        @media(max-width: $md5+px) {
            width: 400px;
            right: calc(50% - 450px);
            bottom: -50px;
        }
        @media(max-width: $md6+px) {
            right: -99px;
            bottom: -30px;
        }
    }
    &Body {
        position: relative;
        z-index: 5;
        padding: 90px 0 190px 0;
        display: grid;
        grid-template-columns: 662px 484px;
        gap: 68px;
        @media(max-width: $md1+px) {
            grid-template-columns: 455px 424px;
            gap: 110px;
            padding: 105px 0;
        }
        @media(max-width: $md2+px) {
            gap: 50px;
        }
        @media(max-width: $md3+px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding: 60px 0 85px 0;
        }
        @media(max-width: $md6+px) {
            padding: 67px 0 350px 0;
        }
        .left {
            .title {
                color: #1B2A38;
            }
            .text {
                margin-top: 20px;
                max-width: 527px;
                color: rgba(0, 0, 0, 0.7);
                @media(max-width: $md4+px) {
                    max-width: 414px;
                }
            }
        }
        .right {
            @media(max-width: $md3+px) {
                max-width: 500px;
            }
            @media(max-width: $md4+px) {
                max-width: 366px;
            }
            .subtitle {
                span {
                    color: #02A69E;
                }
                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
            .btn {
                display: inline-flex;
                padding: 14px 30px;
                margin-top: 40px;
                border: 1px solid #02A69E;
                border-radius: 30px;
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #02A69E;
                cursor: pointer;
                transition: all .3s;
                @media(max-width: $md3+px) {
                    margin-top: 20px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    line-height: 17px;
                }
                &:hover {
                    background: #02A69E;
                    color: #1B2A38;
                }
            }
        }
    }
}