@import '../../../styles/var';

.underBanner {
    position: relative;
    margin-top: -222px;
    @media(max-width: $md1+px) {
        margin-top: -150px;
    }
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
    .title {
        color: #FFFFFF;
        @media(max-width: $md3+px) {
            text-align: center;
        }
        @media(max-width: $md6+px) {
            color: #1B2A38;
        }
    }
    .cards {
        display: flex;
        gap: 20px;
        margin-top: 40px;
        @media(max-width: $md2+px) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
        @media(max-width: $md3+px) {
            grid-template-columns: 295px 295px;
            justify-content: center;
        }
        @media(max-width: $md5+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 600px) {
            grid-template-columns: 295px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .card {
            background: #243443;
            border-radius: 10px;
            padding: 20px 20px 20px 30px;
            width: 340px;
            @media(max-width: $md1+px) {
                width: 295px;
            }
            @media(max-width: $md2+px) {
                width: 100%;
            }
            &Icon {
                width: 40px;
            }
            &Title {
                font-family: $manrope;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 33px;
                color: #4DD1CB;
                margin: 20px 0 10px 0;
                @media(max-width: $md1+px) {
                    font-size: 20px;
                    line-height: 27px;
                }
            }
            &Text {
                font-family: $rubik;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                color: rgba(255, 255, 255, 0.7);
                max-width: 249px;
                @media(max-width: $md1+px) {
                    font-size: 16px;
                }
            }
        }
    }
}