@import "./_var.scss";

body,
html {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.7);
    &.lock {
        overflow: hidden;
    }
}

.preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 1;
    transition: all 0.2s;
    &.hidePreloader {
      opacity: 0;
      pointer-events: none;
    }
}

.font-64 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 87px;
    @media (max-width: $md1+px) {
        font-size: 48px;
        line-height: 66px;
    }
    @media (max-width: $md4+px) {
        font-size: 36px;
        line-height: 49px;
    }
    @media (max-width: $md6+px) {
        font-size: 32px;
        line-height: 140%;
    }
}

.font-48 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 140%;
    @media (max-width: $md1+px) {
        font-size: 40px;
    }
    @media (max-width: $md4+px) {
        font-size: 36px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-36 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    @media (max-width: $md1+px) {
        font-size: 32px;
        line-height: 44px;
    }
    @media (max-width: $md4+px) {
        font-size: 24px;
        line-height: 33px;
    }
}
.font-40 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;

    @media (max-width: $md1+px) {
        font-size: 32px;
        line-height: 44px;
    }
    @media (max-width: $md4+px) {
        font-size: 24px;
        line-height: 33px;
    }
}
.font-32 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    @media (max-width: $md1+px) {
        font-size: 24px;
        line-height: 33px;
    }
    @media (max-width: $md4+px) {
        font-size: 20px;
        line-height: 27px;
    }
    @media (max-width: $md6+px) {
        font-size: 16px;
        line-height: 22px;
    }
}
.font-24 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;

    @media (max-width: $md1+px) {
        font-size: 20px;
        line-height: 27px;
    }
    @media (max-width: $md4+px) {
        font-size: 16px;
        line-height: 22px;
    }
}
.font-20 {
    font-family: $rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    @media (max-width: $md1+px) {
    }
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-18 {
    font-family: $rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    @media (max-width: $md1+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ovf-hidden {
    overflow: hidden;
}

.Dropdown-control {
    font-family: $rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
    padding-right: 30px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.Dropdown-arrow {
    border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent rgba(0, 0, 0, 0.7);
}


.lds-default {
    margin-left: calc(50% - 40px);
    margin-top: calc(50vh - 50px);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #02A69E;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }